var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cu-menu pointer d-flex border flex-column",
    class: {
      'bg-gray-20': _vm.isHovered,
      'bg-white border border-gray-40': !_vm.isHovered
    },
    on: {
      "click": _vm.goToPage,
      "mouseenter": function ($event) {
        _vm.isHovered = true;
      },
      "mouseleave": function ($event) {
        _vm.isHovered = false;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center justify-content-center text-center"
  }, [_c('div', [_c('ba-base-icon', {
    staticClass: "title-icon text-black",
    attrs: {
      "name": _vm.logo
    }
  })], 1), _c('div', {
    staticClass: "cu-menu__title text-black"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "cu-menu__description text-gray-700"
  }, [_vm._v(_vm._s(_vm.description))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }