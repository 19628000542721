var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    class: `wrapper border ${_vm.isCompleted ? 'bg-yellow-10 border-yellow-20' : 'bg-gray-40 border-gray-light'} mb-5`
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('ba-base-icon', {
    staticClass: "mr-2",
    attrs: {
      "name": `${_vm.isCompleted ? 'ba-home-illustration' : 'ba-home-illustration-nc'}`
    }
  }), _c('div', [_c('div', {
    staticClass: "d-flex"
  }, [_c('ba-base-icon', {
    staticClass: "mr-2",
    attrs: {
      "name": "ba-reporting-tool"
    }
  }), _c('h1', {
    class: `wrapper__text--header ${_vm.isCompleted ? 'text-black-100' : 'text-black-60'}`
  }, [_vm._v("Reporting Dashboard")])], 1), _c('p', {
    class: `wrapper__text--description ${_vm.isCompleted ? 'text-gray-900' : 'text-black-50'}`
  }, [_vm._v("Create, review, approve and submit reports to the FRC and receive feedback on reports.")])])], 1), _c('ba-button', {
    class: `border-0 ${_vm.isCompleted ? 'bg-yellow-70 text-gray-dark' : 'bg-black-10 text-black-40'}`,
    attrs: {
      "disabled": !_vm.isCompleted,
      "text": "Go to dashboard"
    },
    on: {
      "click": _vm.goToReports
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }