var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.canCreateReport ? _c('div', {
    staticClass: "checklist__wrapper py-3 pl-3 pr-5 mb-5"
  }, [_c('h4', {
    staticClass: "checklist__text--header text-uppercase"
  }, [_vm._v("Checklist")]), _c('p', {
    staticClass: "checklist__text--description mb-0"
  }, [_vm._v("Complete this list to start reporting")]), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "text-neutral-10 text-sm-2"
  }, [_c('b-form-checkbox', {
    staticClass: "mt-3 d-flex align-items-center",
    attrs: {
      "id": "checkbox-onboarding-2",
      "disabled": "",
      "checked": _vm.isCompanyProfileComplete,
      "name": "checkbox-onboarding-2"
    }
  }, [_c('div', {
    staticClass: "text-gray-dark pt-1"
  }, [_vm._v("Complete Company Profile")])]), _c('b-form-checkbox', {
    staticClass: "mt-3 d-flex align-items-center",
    attrs: {
      "id": "checkbox-onboarding-3",
      "disabled": "",
      "checked": _vm.hasAReviewer,
      "name": "checkbox-onboarding-3"
    }
  }, [_c('div', {
    staticClass: "text-gray-dark pt-1"
  }, [_vm._v("Add reviewer")])]), _c('b-form-checkbox', {
    staticClass: "mt-3 text-white",
    attrs: {
      "id": "checkbox-onboarding-1",
      "disabled": "",
      "checked": _vm.hasASignatory,
      "name": "checkbox-onboarding-1"
    }
  }, [_c('div', {
    staticClass: "text-gray-dark pt-1"
  }, [_vm._v("Add 2 Signatories (Board Chairman & Company Secretary)")])]), _c('b-form-checkbox', {
    staticClass: "mt-3 d-flex align-items-center",
    attrs: {
      "id": "checkbox-onboarding-4",
      "disabled": "",
      "checked": _vm.hasAReport,
      "name": "checkbox-onboarding-4"
    }
  }, [_c('div', {
    staticClass: "text-gray-dark pt-1"
  }, [_vm._v("Create Report")])])], 1), _c('div', {
    staticClass: "d-flex flex-column justify-content-center align-items-center"
  }, [_c('p', {
    staticClass: "progress__wrapper checklist rounded-circle d-flex justify-content-center align-items-center text-white"
  }, [_vm._v(" " + _vm._s(_vm.progress) + "/ "), _c('span', {
    staticClass: "text-sm-1 pt-1"
  }, [_vm._v("4")])]), _c('ba-button', {
    staticClass: "text-sm-2 text-white border-0 py-2 mt-1 bg-gray-dark",
    attrs: {
      "loading": _vm.isLoading,
      "text": !_vm.isLoading ? _vm.buttonText : ''
    },
    on: {
      "click": _vm.takeAction
    }
  })], 1)])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }