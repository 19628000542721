var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    staticClass: "corporate-universe container-fluid pt-6 pb-5 mt-body"
  }, [_c('cu-home-banner'), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [!_vm.isCompleted ? _c('check-list', {
    attrs: {
      "data-v-step": "5"
    }
  }) : _vm._e(), _c('cu-home-sub-banner', {
    attrs: {
      "isCompleted": _vm.isCompleted
    }
  }), _c('b-row', {
    staticClass: "mb-5"
  }, _vm._l(_vm.settingsDivisions, function (corporateUniverseDivision, ix) {
    return _c('b-col', {
      key: ix,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('cu-menu', {
      attrs: {
        "data-v-step": ix === 0 ? 6 : null,
        "title": corporateUniverseDivision.title,
        "description": corporateUniverseDivision.title === 'Change Password' ? `Last changed ${_vm.formatDate(_vm.profileData.dateOfPasswordUpdate)}` : corporateUniverseDivision.description,
        "logo": corporateUniverseDivision.logo,
        "button": corporateUniverseDivision.button,
        "img": corporateUniverseDivision.img,
        "route": corporateUniverseDivision.route,
        "extLink": corporateUniverseDivision.extLink,
        "id": "BA_AMP_OTHER_PRODUCTS"
      }
    })], 1);
  }), 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "bg-gray-10 border border-gray-20 steps"
  }, [_c('h4', {
    staticClass: "text-black-90 steps__header--text"
  }, [_vm._v("For your governance, risk & compliance solution. "), _c('span', {
    staticClass: "text-yellow-120"
  }, [_vm._v("BodAdmin")])]), _c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("@/assets/pngs/hero-motion.png"),
      "alt": "hero-motion"
    }
  }), _c('p', {
    staticClass: "text-black-70 steps__header--subtext"
  }, [_vm._v(" We help your Company create business value through an efficient governance and secretarial technology that is affordable, safe, secure and scalable. ")]), _c('p', {
    staticClass: "text-black-70 steps__header--subtext pt-0 mt-0"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Other BodAdmin Products:")]), _vm._v(" Governance Portal, Corporate Universe, Enterprise Dashboard, Investor Relations, Enterprise Sustainability. ")]), _c('a', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": "https://www.bodadmin.com/",
      "target": "blank"
    }
  }, [_c('ba-button', {
    staticClass: "bg-gray-dark text-white steps__button",
    attrs: {
      "text": "Learn more"
    }
  })], 1)])])], 1)], 1), _c('v-tour', {
    attrs: {
      "name": "myTour",
      "steps": _vm.steps
    }
  }), _c('ChangePasswordModal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }