
import Vue from "vue";
import { HOME } from "@/app/routes/endpoints";

export default Vue.extend({
    props: {
        isCompleted: {
            type: Boolean,
            required: true,
            default: true
        }
    },
    data() {
        return {
            isHovered: false,
        };
    },
    methods: {
        goToReports () {
            this.$router.push({
                name: HOME.REPORTS.NAME,
            });
        }
    },
});
