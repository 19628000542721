var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "wrapper bg-black-100 mb-5"
  }, [_c('div', [_c('h1', {
    staticClass: "wrapper__text--header"
  }, [_vm._v("Home")]), _c('p', {
    staticClass: "wrapper__text--description"
  }, [_vm._v("Welcome to The "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("NCCG Portal")]), _vm._v(", what would you like to do?")])]), _c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("@/assets/pngs/nga-ba.png"),
      "alt": "baXnga"
    }
  })]);

}]

export { render, staticRenderFns }