
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { formatDate } from "@/utils/helpers";
import CuMenu from "../components/home/CuMenu.vue";
import CuHomeBanner from "../components/home/CuHomeBanner.vue";
import CuHomeSubBanner from "../components/home/CuHomeSubBanner.vue";
import CheckList from "../components/home/CheckList.vue";
import ChangePasswordModal from "../components/modals/ChangePasswordModal.vue";
import { SETTINGS, HOME } from "../routes/endpoints";
// import CuFaq from "../components/home/CuFaq.vue";

export default Vue.extend({
    components: { CuMenu, CuHomeBanner, CheckList, CuHomeSubBanner, ChangePasswordModal },
    data() {
        return {
            procedures: [
                "Click the “Start Reporting” button above",
                "Click on the create report button.",
                'Go through the instruction for NCCG, and click on the "Confirm" button.',
                "Fill in your details, and click the “Next” button",
                "Fill in the details for the stakeholders, and click the “Next” button",
                "Fill in the details for structures, and click the “Next” button",
                "Fill in the details for principles, and click the “Next” button",
                "Fill in the details for the signatory, and click the “Next” button",
                "Click the “Submit” button",
            ],
            steps: [
                {
                    target: '[data-v-step="1"]', // We're using document.querySelector() under the hood
                    header: {
                        title: "Get Started",
                    },
                    content: `Click here to Return to the Dashboard`,
                },
                {
                    target: '[data-v-step="2"]', // We're using document.querySelector() under the hood
                    header: {
                        title: "Navigation",
                    },
                    content: `Click here to view available menus, settings and help`,
                },
                {
                    target: '[data-v-step="3"]', // We're using document.querySelector() under the hood
                    header: {
                        title: "Position",
                    },
                    content: `This is your position in this entity`,
                },
                {
                    target: '[data-v-step="4"]', // We're using document.querySelector() under the hood
                    header: {
                        title: "Menu",
                    },
                    content: `Access your profile, settings, change password and logout options`,
                },
                {
                    target: '[data-v-step="5"]', // We're using document.querySelector() under the hood
                    header: {
                        title: "Checklist",
                    },
                    content: `This is a checklist of all the steps you need to take to successfully create and submit reports`,
                },
                {
                    target: '[data-v-step="6"]', // We're using document.querySelector() under the hood
                    header: {
                        title: "Reporting",
                    },
                    content: `Click here to access reports available to your desk`,
                },
            ],
            settings: [
                {
                    title: "My Profile",
                    description: "Edit your personal information",
                    logo: "ba-person-icon",
                    button: "Start",
                    img: "ba-arrow-right-blue",
                    extLink: "",
                    route: SETTINGS.PROFILE.NAME,
                },
                {
                    title: "Company profile",
                    description: "Edit company information and assets",
                    logo: "firm-logo",
                    button: "Start",
                    img: "ba-coming-soon-icon",
                    extLink: "",
                    route: SETTINGS.BUSINESS.NAME,
                },
                {
                    title: "Users, Roles and Profiles",
                    description: "Add/Remove creators, reviewers and signatories",
                    logo: "users-role-icon",
                    button: "Start",
                    img: "ba-coming-soon-icon",
                    extLink: "",
                    route: SETTINGS.USER_ACCESS.NAME,
                },
                {
                    title: "Change Password",
                    description: "14 September 2022",
                    logo: "key-icon",
                    button: "modal",
                    img: "ba-coming-soon-icon",
                    extLink: "",
                    route: "modal-change-password",
                },
                // {
                //     title: "Enterprise Sustainability",
                //     description:
                //         "Build a sustainability strategy, benchmark your data, and develop initiatives that give your company long-term value.",
                //     logo: "ba-enterprise-sustainability-logo",
                //     button: "See More",
                //     img: "ba-coming-soon-icon",
                //     extLink: "https://bodadmin.com/enterprise-sustainability/",
                //     route: SETTINGS.USER_ACCESS.NAME,
                // },
                // {
                //     title: "Dispute Portfolio",
                //     description:
                //         "Manage, store and archive disputes and legal actions involving the company",
                //     logo: "ba-dispute-portfolio-logo",
                //     button: "See More",
                //     img: "ba-coming-soon-icon",
                //     route: SETTINGS.USER_ACCESS.NAME,
                // },
                // {
                //     title: "Contract Domain",
                //     description:
                //         "Setup and manage meetings, decisions and other governance activities. ",
                //     logo: "ba-contract-domain-logo",
                //     button: "See More",
                //     img: "ba-coming-soon-icon",
                //     route: SETTINGS.USER_ACCESS.NAME,
                // },
            ],
            recentActivities: [],
        };
    },
    computed: {
        ...mapGetters({ profileData: "auth/getUserData" }),
        ...mapState("reports", ["reports"]),
        ...mapState("users", {
            reviewers: (state: any) => state.levels?.find((level: any) => level.category === "ReportReviewer")?.users,
            signatories: (state: any) => state.levels?.find((level: any) => level.category === "ReportSignatory")?.users,
        }),
        isCompanyProfileComplete(): boolean {
            return this.profileData.isCompanyProfileUpdated;
        },
        isPersonalProfileComplete(): boolean {
            return this.profileData.isProfileUpdated;
        },
        hasAReviewer(): boolean {
            return this.reviewers?.length > 0;
        },
        hasASignatory(): boolean {
            return this.signatories?.length > 1;
        },
        hasAReport(): boolean {
            return this.reports.length > 0;
        },
        isCompleted(): boolean {
            return this.isCompanyProfileComplete && this.hasAReviewer && this.hasASignatory && this.hasAReport;
        },
        isMenuAccessible(): boolean {
            return this.profileData?.roles?.includes("Admin") || this.profileData?.roles?.includes("ReportCreator");
        },
        settingsDivisions(): any {
            const val = [];
            if (this.isMenuAccessible) {
                val.push(
                    {
                        title: "My Profile",
                        description: "Edit your personal information",
                        logo: "ba-person-icon",
                        button: "Start",
                        img: "ba-arrow-right-blue",
                        extLink: "",
                        route: SETTINGS.PROFILE.NAME,
                    },
                    {
                        title: "Company profile",
                        description: "Edit company information and assets",
                        logo: "firm-logo",
                        button: "Start",
                        img: "ba-coming-soon-icon",
                        extLink: "",
                        route: SETTINGS.BUSINESS.NAME,
                    },
                    {
                        title: "Users, Roles and Profiles",
                        description: "Add/Remove creators, reviewers and signatories",
                        logo: "users-role-icon",
                        button: "Start",
                        img: "ba-coming-soon-icon",
                        extLink: "",
                        route: SETTINGS.USER_ACCESS.NAME,
                    },
                    {
                        title: "Change Password",
                        description: "14 September 2022",
                        logo: "key-icon",
                        button: "modal",
                        img: "ba-coming-soon-icon",
                        extLink: "",
                        route: "modal-change-password",
                    },
                );
            } else {
                val.push(
                    {
                        title: "My Profile",
                        description: "Edit your personal information",
                        logo: "ba-person-icon",
                        button: "Start",
                        img: "ba-arrow-right-blue",
                        extLink: "",
                        route: SETTINGS.PROFILE.NAME,
                    },
                    {
                        title: "Change Password",
                        description: "14 September 2022",
                        logo: "key-icon",
                        button: "modal",
                        img: "ba-coming-soon-icon",
                        extLink: "",
                        route: "modal-change-password",
                    },
                );
            }

            return val;
        },
    },
    methods: {
        formatDate,
        ...mapActions({
            fetchProfile: "auth/fetchProfile",
            fetchReportList: "reports/fetchReportList",
        }),
    },
    mounted() {
        this.fetchReportList();
        // this.$tours.myTour.start();
    },
});
